import { useCallback, useEffect, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useProperty } from "../../context/PropertyContext";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import Timestamp from "../../formatters/Timestamp";
import ConnectorType from "./ConnectorType";
import BadgeConnectorStatus from "./BadgeConnectorStatus";
import { Button } from "../../components/button";

interface ConnectorRouteProps {}

const ConnectorRoute: React.FC<ConnectorRouteProps> = () => {
  const [connector, setConnector] = useState<JUHUU.Connector.Object | null>(
    null
  );
  const { connectorId } = useParams<{ connectorId: string }>();
  const [connectorMessageArray, setConnectorMessageArray] = useState<
    JUHUU.ConnectorMessage.Object[]
  >([]);

  const { property } = useProperty();

  const handleRefresh = useCallback(async () => {
    if (property === null) {
      return;
    }

    if (connectorId === undefined) {
      return;
    }

    const connectorQueryPromise = juhuu.connectors.retrieve({
      connectorId: connectorId,
    });

    const connectorMessageQuery = await juhuu.connectorMessages.list(
      {
        propertyId: property?.id,
        connectorId: connectorId,
      },
      {
        limit: 5,
      }
    );

    const connectorQuery = await connectorQueryPromise;

    if (connectorQuery.ok === true) {
      setConnector(connectorQuery.data.connector);
    }

    if (connectorMessageQuery.ok === true) {
      setConnectorMessageArray([...connectorMessageQuery.data]);
    }
  }, [connectorId, property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleShowMore = useCallback(async () => {
    if (property === null) {
      return;
    }

    if (connectorId === undefined) {
      return;
    }

    const connectorMessageQuery = await juhuu.connectorMessages.list(
      {
        propertyId: property?.id,
        connectorId: connectorId,
      },
      {
        limit: 100,
        skip: connectorMessageArray.length,
      }
    );

    if (connectorMessageQuery.ok === true) {
      setConnectorMessageArray([
        ...connectorMessageArray,
        ...connectorMessageQuery.data,
      ]);
    }
  }, [connectorId, property, connectorMessageArray]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>{connector?.name}</Heading>
          <Text>Connector</Text>
          <BadgeConnectorStatus status={connector?.status} />
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Text>{connectorId}</Text>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Type</Text>
          <Text>
            <ConnectorType type={connector?.type} />
          </Text>
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Last inbound</Text>
          <Timestamp timestamp={connector?.lastInboundAt} />
        </div>
        <div className="flex justfy-start flex-col items-start border-l-2 pr-8 pl-2">
          <Text>Last outbound</Text>
          <Timestamp timestamp={connector?.lastOutboundAt} />
        </div>
      </div>
      <Subheading>Messages</Subheading>
      <Divider className="mb-2" />
      {connectorMessageArray.map((connectorMessage) => (
        <div
          key={connectorMessage.id}
          className={`flex flex-row items-center ${
            connectorMessage.direction === "inbound"
              ? "justify-start"
              : "justify-end"
          }`}
        >
          <div
            className={`width-1/2 p-2 ${
              connectorMessage.direction === "inbound"
                ? "bg-zinc-200"
                : "bg-lime-400"
            } rounded-2xl mb-2`}
          >
            <Text style={{ color: "#000" }}>{connectorMessage.message}</Text>
            <Text
              style={{
                fontSize: "0.75rem",
                textAlign:
                  connectorMessage.direction === "inbound" ? "left" : "right",
              }}
            >
              <Timestamp
                timestamp={connectorMessage.createdAt}
                display="seconds"
              />
            </Text>
          </div>
        </div>
      ))}
      <div className="flex flex-row justify-center">
        <Button onClick={handleShowMore}>Show more messages</Button>
      </div>
    </>
  );
};

export default ConnectorRoute;
