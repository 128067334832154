import { JUHUU } from "@juhuu/sdk-ts";

interface LinkTypeProps {
  type: JUHUU.Link.Object["type"] | null | undefined;
}

const LinkType: React.FC<LinkTypeProps> = ({ type }) => {
  switch (type) {
    case "fiveLetterQr": {
      return <>QR-Code (5 letters)</>;
    }

    default: {
      return null;
    }
  }
};

export default LinkType;
