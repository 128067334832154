import { useCallback, useEffect, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import { Heading, Subheading } from "../../components/heading";
import { useParams } from "react-router-dom";
import { Text } from "../../components/text";
import { Divider } from "../../components/divider";
import LinkType from "./LinkType";
import { useProperty } from "../../context/PropertyContext";

interface LinkRouteProps {}

const LinkRoute: React.FC<LinkRouteProps> = () => {
  const [link, setLink] = useState<JUHUU.Link.Object | null>(null);
  const { linkId } = useParams<{ linkId: string }>();

  const { property } = useProperty();

  const handleRefresh = useCallback(async () => {
    if (linkId === undefined) {
      return;
    }

    if (property === null) {
      return;
    }

    // fetch link and tariffArray
    const query = await juhuu.links.retrieve({
      linkId: linkId,
    });

    if (query.ok === false) {
      return null;
    }

    setLink(query.data.link);
  }, [linkId, property]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 pb-2">
        <div className="flex gap-4 items-center">
          <Heading>{link?.name}</Heading>
        </div>

        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Text>{linkId}</Text>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex justify-start flex-row items-center py-4">
        <div className="flex justfy-start flex-col items-start pr-8">
          <Text>Type</Text>
          <Text>
            <LinkType type={link?.type} />
          </Text>
        </div>
      </div>

      <Subheading className="mt-8">Devices</Subheading>
      <Text>
        Devices at this link can be used after a user payed for their rental.
      </Text>
      <Divider />
    </>
  );
};

export default LinkRoute;
/*
amountAuthorized: number; // amount of the link that was initially authorized
    amountAuthorizedWithoutServiceFee: number; // amount of the link that was authorized without the service fee
    amountFinal: number | null; // amount that was withdrawn from the user
    amountCaptured: number | null; // amount that was captured from the user
    amountToPayout: number | null;*/
