import { CurrencyCode } from "@juhuu/sdk-ts";
import { useMemo } from "react";

interface AmountProps {
  amount: number | null | undefined;
  currencyCode: CurrencyCode | undefined | null;
}

const Amount: React.FC<AmountProps> = ({ amount, currencyCode }) => {
  const formattedAmount = useMemo(() => {
    if (
      amount === null ||
      amount === undefined ||
      currencyCode === null ||
      currencyCode === undefined
    ) {
      return "-.--";
    }

    let currency: string;

    switch (currencyCode) {
      case "eur": {
        currency = "EUR";
        break;
      }

      case "usd": {
        currency = "USD";
        break;
      }

      case "gbp": {
        currency = "GBP";
        break;
      }

      case "nok": {
        currency = "NOK";
        break;
      }

      case "sek": {
        currency = "SEK";
        break;
      }

      case "chf": {
        currency = "CHF";
        break;
      }

      case "dkk": {
        currency = "DKK";
        break;
      }

      case "pln": {
        currency = "PLN";
        break;
      }

      case "czk": {
        currency = "CZK";
        break;
      }

      case "huf": {
        currency = "HUF";
        break;
      }

      case "bam": {
        currency = "BAM";
        break;
      }

      case "rsd": {
        currency = "RSD";
        break;
      }

      case "isk": {
        currency = "ISK";
        break;
      }

      case "cad": {
        currency = "CAD";
        break;
      }

      default: {
        return "-.--";
      }
    }

    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: currency,
    });

    return formatter.format(amount / 100);
  }, [amount, currencyCode]);

  return <>{formattedAmount}</>;
};

export default Amount;
